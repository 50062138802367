import React from 'react';
import styles from "./brand.module.css";
import boy from "../../assets/misc/boy.png";
import { Link } from 'react-router-dom';


//Function consumes words from wordArray below and loops through displaying one word at a time
const wordArray = ['Major Hotels & Casinos', 'Restaurants & Bars', 'Event Centers', 'National Sports Teams', 'Comedy Clubs', 'AV Integrators', 'Sportsbooks and more...']

let i = 0;

setInterval(function() {
  document.getElementById('words').innerHTML = wordArray[i];
  if (i === (wordArray.length - 1)) {
    i = 0;
  } else {
    i++;
  }
}, 3000)

const Brand = () => {
  
  return (
    <div className={`${styles.brand} ${styles.section__padding}`}>
      <div className={styles.clients}>
      <img src={boy} alt='Lets Work Together' />
      <div className={styles.brandTitle}>
        <h1>We've Had The Opportunity To Work with..</h1>
        <div className={`${styles.textChange} ${styles.gradient__text}`}>
        <h2 id='words'>{wordArray[0]}</h2>
        </div>
        <Link to='/contact'>
        <button className={styles.contactLink}>Let's Work Together</button>
        </Link>
      </div>
      </div>
    </div>
  )
}

export default Brand;