import styles from "./article2.module.css";
import { Link } from 'react-router-dom';
import cdlogo from "../../assets/Logo-Code-Distillers/sub-Logo.png";

//BLOG POST TO BE REFACTORED INTO SINGLE COMPONENT THE MAPS DATA INTO INDIVIDUAL ARTICLES
const Article2 = () => {
  return (
    <div className={styles.post}>
      <div className={styles.postDate}>
        <span>December 15 2022 &#183; 5 min read</span>
        <Link to='/blog'>
        <button>X</button>
        </Link>
      </div>
        <div className={styles.infoContent}>
        <div className={styles.textArea}>
        <h1>
          7 Secrets For Hiring The Right AV Company
        </h1>
          <p>
            Today, more business owners are realizing the value of a professional AV company. This has led to increased competition between vendors as they fight for market share. While this is good news for consumers, it can also make it difficult to find the right AV company for your next project. With so many options available, it's important that you do your homework before making any final decisions. Here are seven secrets that will help you identify quality AV companies:
          </p>
        </div>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          #1: Choose Quality Over Price
        </h2>
          <p>
            When it comes to choosing your AV company, quality is the most important thing. Quality is something that cannot be measured with a checklist or an estimate. You know it when you see it, but we will try to give some pointers on what makes a good AV company stand out from the rest.
          </p>
          <p>
            Quality is not just about having the best equipment — although that's certainly important! It also means taking care of every little detail in your space and making sure everything runs smoothly for your business and potentially guests or clients.
          </p>
        </div>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>
        <h2>
          #2: Reputation
        </h2>
          <p>
            When hiring an AV company, reputation is a major factor to consider. It is important to look for companies that have been in business for more than 10 years and have a solid reputation. You can check this information by looking at their website and reading reviews on sites like Yelp.
          </p>
          <p>
            You can also ask for references from previous clients or testimonials from previous clients. You may also want to see if there are any videos or photos online of past projects they have worked with others on.
          </p>
        </div> 
        </div>
        <div className={styles.infoContent}>  
        <div className={styles.textArea}>  
        <h2>
          #3: Years in Business
        </h2>
          <p>
            Years in business is a great indicator of how long the company has been around, and therefore how much experience they have. Companies that have been around for longer are likely to be more reliable, as they'll have had more time to work out any kinks in their processes or services.
          </p>
          <p>
            If you're considering hiring an AV company, one way to find out how long they've been in business is by looking at their website—the majority will list this information on their "about" page or elsewhere on their site. However, just because a company has been around for many years doesn't always mean that it's right for you! While it's true that older companies tend to be more reliable than new ones, there are still some things you should look into when choosing an AV service provider (such as whether they provide references).
          </p>
        </div>
        </div>
        <div className={styles.infoContent}>
        <div className={styles.textArea}>  
        <h2>
          #4: On-Staff Programmer
        </h2>
          <p>
            You'll have to decide whether programming is an important part of your AV project and let me tell you know "IT IS". Programming is what make all your expensive equipment actually WORK as your system is intended to. Better yet a good on staff programmer may give you insight on how your system can be utilized in ways that you may not have even thought of. 
          </p>
          <p>
            That said it's best if the programmer who actually creates and programs your system works for the AV company. This will mean that they can troubleshoot any problems you might have with the system and know the ins and outs of your particular installation better than an outside contractor could. Many times this part is out sourced and if it is you outta know about it and figure out how you'll be able to go about maintenance or program updates and changes.
          </p>
          </div>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          #5: Certifications
        </h2>
          <p>
            Certifications are also super important. At Code-Distillers we make a point to stay up to date on our certifications and take additional course throughout each year to remain sharp. When anything in the industry is or will be changing we know about it and stay ahead of it. 
          </p>
          <p>
            If you're considering a company that has a few certifications or more, awesome! If not, take a close look at their portfolio and make sure they can do what you want them to do. If it's a small job and you don't need much from them besides some equipment setup and maybe some basic lighting design, then it may not matter either way (though if they have a lighting designer on staff who knows how to set up LED lights properly, that should have been listed in their portfolio).
          </p>
        </div> 
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          #6: On-Site Maintenance
        </h2>
          <p>
            You should always get a service agreement with your AV company. You want to know that they have someone available to address any problems that may arise after your system is up and running. This person should also have replacement parts on hand in case anything breaks during the event, and some companies even offer warranties for their equipment or services.
          </p>
          <p>
            You’ll also want to ask about how long it takes for them to respond if there is an emergency situation during the event (like a power surge or equipment failure). This can be incredibly important if something goes wrong halfway through your presentation and you need help from technical staff immediately! Make sure you cover all your bases when closing a deal with an AV Company
          </p>
        </div> 
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          #7: Project Management
        </h2>
          <p>
            Project management is a huge part of any AV project. Who is gonna be taking the lead on project planning, scheduling and budgeting? How are you going to keep everyone on the same page and ensures that all of your details are taken care of. Ultimately, it's the Project Managers responsibility to see that a project runs smoothly from start to finish. This should allow you to sit back and watch it come together without any hiccups or surprises.
          </p>
          <p>
            They'll be in charge of coordinating with other vendors in order to ensure smooth communication between them all when it comes time for setup and tear down—this way everything goes according to plan!
          </p>
        </div> 
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
        Use these six secrets to find the right AV company for your next project.
        </h2>
          <p>
          You’ve just been given the green light on a big project. Congrats! But before you start planning and scheduling your next meeting, consider these six secrets to hiring the right AV company for your next project.
          </p>
          <ul>
            <li>Quality over price: It’s tempting to go with the lowest bidder, but remember that you get what you pay for. A lower-cost option may sound great at first because of its cost savings. However, if it doesn’t deliver on quality, then the savings won’t matter in the end.
            </li>
            <li>Reputation: If you're not sure whether the AV company you're considering is right, call them up and ask questions! You'll want to know about their experience working with similar clients, what kind of equipment they use, whether they provide backup equipment in case of technical difficulties (which will be useful if something breaks), and maybe how long it may take them to set everything up.
            </li>
            <li>Years in Business: If you're considering a company that has been around for only a few years, it's important to look at their reviews and ask questions about what they've learned in their short time as an event planning company.
            </li>
            <li>On-Staff Programmer:  A local AV company may be able to provide programming services as well as installation, but if you need a programmer on staff, then it's best if they're part of the same company. A good way to find out whether an AV company has on-staff programmers is to ask for references and call them up yourself.
            </li>
            <li>Certifications: Certifications are a way for AV companies to show that they've done their homework.
            </li>
            <li>On-Site Maintenance: You should also ask about what kind of compatibility testing is done before the event. Some companies will test all of their equipment, while others may only test some components or sub-systems (like the projector and screen). If you have a large AV setup with many different components, it’s important to make sure that everything works together properly before your event starts.
            </li>
            <li>Project Management: Project managers are also responsible for communicating with you throughout the process. This includes sending you an agenda of your event, a timeline of when things will happen, as well as any changes made to either. They should also be available to answer any questions that may arise and ensure that everyone on the AV team is working in sync with one another.
            </li>
          </ul>
        </div> 
        </div>
        <h3>
          Let's the experts at 
          <br />
          <Link to='/contact'><img src={cdlogo} alt='Link logo to Code-Distillers.com' /></Link>
          <br />
          manage your next AV project.
        </h3>
        <div className={styles.postAuthor}>
        <span>
          Written 12/15/2022 by ~ Ty Gyorfy [Software Engineer @Code-Distillers]
        </span>
        <Link to='/blog'>
        <button>Close X</button>
        </Link>
      </div>
  </div>
  )
}

export default Article2;