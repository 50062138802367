import React from 'react';
import styles from './zombie.module.css';
import zombie from '../../assets/misc/avatar_icons_2.png';

const Zombie = () => {
  return (
    <div className={styles.zombiePage}>
      <div>
        <h1>Ooops.. This Page is coming together.</h1>
      </div>
      <div className={styles.zombie}>
        <img src={zombie} alt='Zombie404' />
      </div>
      <div>
        <h1>Will Rise Again SOON</h1>
        <p></p>
      </div>
    </div>
  )
}

export default Zombie;