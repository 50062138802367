import React, { useState } from 'react';
import styles from "./navbar.module.css";
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from "../../assets/Logo-Code-Distillers/sub-Logo.png";
import { Link } from 'react-router-dom'

//NAVIGATION
const Menu = () => (

  <>
    <Link to='/'>  
      <p>Home</p>
    </Link> 
    
    <Link to='/av'>
      <p>AV Programming</p>
    </Link>

    <Link to='/design'>
      <p>UI/UX Design</p>
    </Link> 
    
    <Link to='/blog'>
      <p>Blog</p>
    </Link> 
    
    <Link to='/contact'>
      <p>Contact</p>
    </Link> 
    </>
  )


const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className={styles.cd__navbar}>
      <div className={styles.navbar__links}>
        <div className={styles.navbar__links__logo}>
          <Link to='/'>
          <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={styles.navbar__links__container}>
            <Menu />
        </div>
      </div>
      <div className={styles.navbar__menu}>
      {toggleMenu
        ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} /> 
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />}
      {toggleMenu && (
        <div className={`${styles.navbar__menu__container} ${styles.scale__up__center}`}>
          <div className={styles.navbar__menu__container__links}>
            <Menu />
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default Navbar;