import React from 'react';
import styles from "./feature.module.css";

const Feature = ({title, text}) => {
  return (
    <div className={styles.av__container__feature}>
      <div className={styles.av__container__feature__title}>
        <div />
        <h1>{title}</h1>
      </div>
      <div className={styles.av__container__feature__text}>
        <p>{text}</p>
      </div>
    </div>
  )
}
export default Feature;