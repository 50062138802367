import React from 'react';
import { Header, Footer, Blog, Possibility, Zombie, AVPage, DesignPage } from './containers';
import { Contact, Navbar, Article, Article2, Career, Brand, AV, Design, Scheduler } from './components';
import styles from './App.module.css';
import Typewriter from './components/brand/typewriterH1/Typeh1';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Code-Distillers Software & AV Programming in Las Vegas.</title>
        <meta name="description" content="Solutions Experts for AV Programming, Web Design and Consulting. Certified Crestron Service Provider working with other products like Q-sys, AV Pro, Extreme, BSS, etc." />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
        <Header />
      </div>
      <AV />
      <Typewriter />
      <Brand />
      <Design />
      <Possibility />
      <Footer />
    </div>
  )
}

const AVMain = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>AV Programming Done Right - Code-Distillers</title>
        <meta name="description" content="Audio/Video Services, AV Programming, Creston, Q-Sys, AV Pro and more. Automation Systems for lighting, smart controls, etc." />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
      </div>
      <AVPage />
      <Footer />
    </div>
  )
}

const DesignMain = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Design UI/UX Development - Code-Distillers</title>
        <meta name="description" content="Custom and template designs for website and control panels" />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
      </div>
      <DesignPage />
      <Footer />
    </div>
  )
}

const BlogPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Stay in the KNOW - Code-Distillers</title>
        <meta name="description" content="Blog posts relating to AV, Web Development, SEO and everything Tech." />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
      </div>
      <Blog />
      <Footer />
    </div>
  )
}

const PostPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Must see CES Las Vegas 2023 - Code-Distillers</title>
        <meta name="description" content="Crestron, Samsung, Playstation, LG and SkyX are a couple of the booths as CES we will be checking out and you should too." />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
      </div>
      <Article />
      <Footer />
    </div>
  )
}

const PostPage2 = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Secret to hiring the right AV Company - Code-Distillers</title>
        <meta name="description" content="Secrets to hiring the right AV company for your next project" />
      </Helmet>
      <div className={styles.gradient__bg}>
        <Navbar />
      </div>
      <Article2 />
      <Footer />
    </div>
  )
}

const ContactPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Let's Talk About it - Code-Distillers</title>
        <meta name="description" content="Blog post relating to AV, Web Development, SEO and everything Tech " />
      </Helmet>
      <div className={styles.gradient__bg}>
      <Navbar />
      </div>
      <Contact />
      <Scheduler />
      <Blog />
      <Footer />
    </div>
  )
}

const OurWorkPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>We Do The Impossible - Code-Distillers</title>
        <meta name="description" content="Blog post relating to AV, Web Development, SEO and everything Tech " />
      </Helmet>
      <div className={styles.gradient__bg}>
      <Navbar />
      </div>
      <Zombie />
      <Footer />
    </div>
  )
}

const CareerPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>JOIN the TEAM - Code-Distillers</title>
        <meta name="description" content="We hire creatives and critical thinkers. Our team of developers is growing. Submit your resume here today" />
      </Helmet>
      <div className={styles.gradient__bg}>
      <Navbar />
      </div>
      <Career />
      <Footer />
    </div>
  )
}

const OurTeamPage = () => {
  return (
    <div className={styles.App}>
      <Helmet>
        <title>Meet the Crew @ Code-Distillers</title>
        <meta name="description" content="Find more information about our team members and how they make Code-Distillers the BEST" />
      </Helmet>
      <div className={styles.gradient__bg}>
      <Navbar />
      </div>
      <Zombie />
      <Footer />
    </div>
  )
}


const App = () => {
  return (
    <Routes>
      <Route path='/' exact element={<HomePage />} />
      <Route path='/av' element={<AVMain />} />
      <Route path='/design' element={<DesignMain />} />
      <Route path='/blog' element={<BlogPage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/ourWork' element={<OurWorkPage />} />
      <Route path='/ourTeam' element={<OurTeamPage />} />
      <Route path='/career' element={<CareerPage />} />
      <Route path='/top-5-at-CES' element={<PostPage />} />
      <Route path='/how-to-pick-the-right-AV-Company' element={<PostPage2 />} />
    </Routes>
  );
}

export default App;

