import React from 'react';
import styles from './blog.module.css';
import Article from '../../components/article/Article';
import { Link } from 'react-router-dom';
import { Article2 } from '../../components';

const Blog = () => {
  return (
    <div className={styles.blog}>
      <h1>Tech Industry Blog</h1>
      <p>Stay up to date on the newest technology and changes coming to the AV industry</p>
      <Link to='/top-5-at-CES'>
      <div className={styles.blogCards}>
        <div className={`${styles.card} ${styles.card1}`}>
          <h2>Top 5 things to see at 2023 CES</h2>
          <div className={styles.post}>
            <Article />
          </div>
        </div>
      </div>
      </Link>
      <Link to='/how-to-pick-the-right-AV-Company'>
      <div className={styles.blogCards}>
        <div className={`${styles.card} ${styles.card2}`}>
          <h2>7 Secrets to hiring the right AV Company</h2>
          <div className={styles.post}>
            <Article2 />
          </div>
        </div>
      </div>
      </Link>
    </div>
  )
}

export default Blog;