import React from 'react';
import styles from "./career.module.css";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

const Career = () => {
  return (
    <div className={styles.career}>
      <h1>APPLY NOW</h1>
      <h2>There are currently no open positions posted at this time.</h2>
      <h3>However we are always accepting and reviewing applicant resumes.</h3>
      <button>
      <ButtonMailto label="Submit Resume" mailto="mailto:tgyorfy@code-distillers.com" />
      </button>
    </div>
  )
}

export default Career;