import React from 'react';
import styles from "./article.module.css";
import TSW from "../../assets/misc/tsw70.png";
import Samsung from "../../assets/misc/bioSamsung.webp";
import PSVR from "../../assets/misc/PSVR.png";
import LG from "../../assets/misc/LG.webp";
import Sky from "../../assets/misc/Skyx.png";
import CES from "../../assets/misc/CES.png";
import { Link } from 'react-router-dom';

//BLOG POST TO BE REFACTORED INTO SINGLE COMPONENT THE MAPS DATA INTO INDIVIDUAL ARTICLES
const Article = () => {
  return (
    <div className={styles.post}>
      <div className={styles.postDate}>
        <span>December 5 2022 &#183; 5 min read</span>
        <Link to='/blog'>
        <button>X</button>
        </Link>
      </div>
        <div className={styles.infoContent}>
        <div className={styles.textArea}>
        <h1>Top 5 Things to see at <a href="https://www.ces.tech/" target="_blank" rel="noopener noreferrer">CES 2023</a></h1>
          <p>CES is the world’s largest tech show, and it happens every year in Las Vegas. If you’re not familiar with CES, it stands for the Consumer Electronics Show, and it’s where all the big tech companies go to show off their latest and greatest products. CES is a huge event, and it can be overwhelming to try to figure out what to see.</p>
          <p>From the latest in AV and TV technology to the newest in car tech, there’s something for everyone at CES.</p>
          <p>That’s why we’ve put together a list of the "Top 5 things to see at CES 2023".</p>
        </div>
        <a href="https://www.ces.tech/" target="_blank" rel="noopener noreferrer">
          <div className={styles.blogImg}>
            <img src={CES} alt="CES" />
          </div>
          </a>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          <a href="https://www.crestron.com/Products/Featured-Solutions/TSW-70-Series" target="_blank" rel="noopener noreferrer">#1: Crestron TSW 70</a>
        </h2>
          <p>Crestron is known for its high-end, customizable control systems and the TSW 70 is its latest touchscreen offering.</p>
          <p>The 7-inch display is designed for use in residential and commercial settings, and can be controlled via Crestron's system or via an iPad/iPhone app. The TSW 70 features an edge-to-edge glass design with a resolution of 1280x800, and can be used to control lighting, music, video, climate, security, and more.</p>
          <p>Crestron is moving towards a more elegant touch panel that will have a completely custom UI using HTML5 or VT Pro-e®. The tools like VT Pro have given programmers and designers serious difficulties designing with their limited capabilities. There are high hopes the GUI tool will be updated but more than likely it is time to brush up on your front-end skills or add a front-end</p>
          <p>We believe Crestron UI/UX is much better suited to be written and styled with HTML, CSS and Javascript. With library options like Svelt, React and others, developers can create much more customizable and easy to use</p>
        </div>
        <a href="https://www.crestron.com/Products/Featured-Solutions/TSW-70-Series" target="_blank" rel="noopener noreferrer">
          <div className={styles.blogImg}>
            <img src={TSW} alt="TSW 70 Touch Panel" />
          </div>
          </a>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>
        <h2>
          <a href="https://semiconductor.samsung.com/security-solution/biometric-card/part-number/s3b512c/" target="_blank" rel="noopener noreferrer">#2: Samsung S3B512C Biometric Card</a>
        </h2>
          <p>The Samsung S3B512C security chip is one of the top things to see at CES this year. The chip is designed to provide a high level of security for devices that use it. The chip has a number of features that make it unique, including:</p>
            <p>-AES256 encryption support</p>
            <p>-Hardware based key storage</p>
            <p>-Tamper resistant hardware</p>
          <p>Samsung's all-in-one solution offers biometric cards everything they need to make everyday transactions safer and more convenient. Samsung's chipset adds new levels of security to transactions with an anti-spoofing Fingerprint Matching Algorithm that ensures the safest possible payment experience.</p>

          <p>These features make the Samsung S3B512C security chip one of the most secure options available for devices that need to protect sensitive data. Optimized for the contactless era, Samsung's biometric card solution makes everyday interactions safer by eliminating the need to enter a PIN on a keypad.</p>
        </div> 
        <a href="https://semiconductor.samsung.com/security-solution/biometric-card/part-number/s3b512c/" target="_blank" rel="noopener noreferrer">
        <div className={styles.blogImg}>
            <img src={Samsung} alt="Samsung Biometric Card" />
          </div>
          </a>
        </div>
        <div className={styles.infoContent}>  
        <div className={styles.textArea}>  
        <h2>
          <a href="https://www.playstation.com/en-us/ps-vr2/" target="_blank" rel="noopener noreferrer">#3: Sony PlayStation VR 2</a>
        </h2>
          <p>Advances in VR Technology will either inspire you to get one yourself or have you seriously contemplating simulation theory 😅. </p>
          <p>There are a lot of things to see at CES, and Sony's PlayStation VR 2 is definitely one of them. The new headset is said to be much more comfortable and offer a higher resolution than the original VR, and it's also got a new processing unit that promises to make the experience even more immersive. With all of these improvements, Sony is sure to make a big splash in the VR world with their latest headset.</p>
        </div>
        <a href="https://www.playstation.com/en-us/ps-vr2/" target="_blank" rel="noopener noreferrer">
        <div className={styles.blogImg}>
            <img src={PSVR} alt="Playstation VR" />
          </div>
          </a>
        </div>
        <div className={styles.infoContent}>
        <div className={styles.textArea}>  
        <h2>
          <a href="https://www.lg.com/us/tvs/lg-OLED88Z9PUA-signature-oled-8k-tv" target="_blank" rel="noopener noreferrer">#4: LG SIGNATURE OLED 8K TV</a>
        </h2>
          <p>Displays are a big part of the AV industry and this beast of a TV is quite impressive to say the least.</p>
          <p>When it comes to TVs, there are few companies that can hold a candle to LG. The South Korean electronics giant has been at the forefront of TV technology for years, and they're showing no signs of slowing down. At this year's CES, LG is debuting their new SIGNATURE OLED 8K TV. This bad boy is packing some serious firepower, with an 8K resolution and support for HDR10+ and Dolby Vision. In other words, it's the most advanced TV on the market.</p>
          <p>If you're looking for the best possible viewing experience, the LG SIGNATURE OLED 8K TV is the way to go. The picture quality is absolutely stunning, and the added features only serve to enhance the experience. If you're in the market for a new TV, be sure to check out the LG SIGNATURE OLED 8K TV.</p>
          </div>
          <a href="https://www.lg.com/us/tvs/lg-OLED88Z9PUA-signature-oled-8k-tv" target="_blank" rel="noopener noreferrer">
        <div className={styles.blogImg}>
            <img src={LG} alt="LG 8k Display TV" />
          </div>
          </a>
        </div>
        <div className={styles.infoContent}> 
        <div className={styles.textArea}>  
        <h2>
          <a href="https://skyxplug.com/" target="_blank" rel="noopener noreferrer">#5: SKYX's Smart Sky Ceiling Plug</a>
        </h2>
          <p>Smart Technology Company SKYX can help make your ceiling fixures smart, with easy installation of light fixtures in seconds and product support with the SykHome app and voice assistance.</p>
          <p>If you're looking for a unique way to spruce up your home, office, or any other space, then you'll want to check out SKYX's Smart Sky Ceiling Plug. This innovative product allows you to change the color and brightness of your ceiling, creating a custom look that's perfect for any setting. With the easy-to-use app, you can choose from a variety of preset options or create your own custom designs. The possibilities are endless!</p>
        </div> 
        <a href="https://skyxplug.com/" target="_blank" rel="noopener noreferrer">
          <div className={styles.blogImg}>
            <img src={Sky} alt="SkyX Plug" />
          </div>
          </a>
        </div>
        <h3>
          Enjoy CES 2023 and thanks for reading...
        </h3>
        <div className={styles.postAuthor}>
        <span>Written 12/5/2022 by ~ Ty Gyorfy [Software Engineer @Code-Distillers]</span>
        <Link to='/blog'>
        <button>Close X</button>
        </Link>
      </div>
</div>
  )
}

export default Article;