import './typeh1.css'

// reveal function selects area within window and waits for it to be scrolled to before running, grabs text from 
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
    } else {
        reveals[i].classList.remove("active");
    }
    }
}

//Waits to run reveal "typewriter animate" until scrolled to reveal section
window.addEventListener("scroll", reveal);

const Typewriter = () => {
    return (
    <div className="type-container">
        <div className="type-text reveal">
            {/* H1 TEXT passed into reveal functions */}
            <h1>Trusted by Industry Leaders</h1>
        </div>
    </div>
    )
}

export default Typewriter;