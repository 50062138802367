import React from 'react';
import styles from "./contact.module.css";
import Notice from "../../assets/misc/notification_illustration12.png"
import { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_embdjct',
        'template_o5texca',
        form.current,
        'MalPMoYpKLnSeFTQZ'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <div className={styles.contact}>
      <div className={styles.hello}>
        <img src={Notice} alt='Email Cartoon' />
      </div>
      <div className={styles.formBox}>
      <h2 className={styles.msgTitle}>How can we help?</h2>
      <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className={styles.half}>
                <label htmlFor='Name'>Name:</label>
                  <input placeholder="Your Name" type="text" name="name" required />
                </li>
                <li className={styles.half}>
                <label htmlFor='Email'>Email:</label>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    name="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                  />
                </li>
                <li>
                  <label htmlFor='Phone number' >Phone Number:</label>
                  <input
                    placeholder="702-777-7777"
                    type="tel"
                    name="number"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    minLength='10'
                    maxLength='12'
                    required
                  />
                </li>
                <li>
                <label htmlFor='Message' >Message:</label>
                  <textarea
                    placeholder="Please include project ideas/vision, dead lines & all equipment both needed & owned"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className={styles.flat__button} value="SEND" />
                </li>
              </ul>
            </form>
      </div>
    </div>
  )
}

export default Contact;