import React from 'react';
import styles from './footer.module.css';
import CSP from '../../assets/companies/CSP.jpeg';
import { Link } from 'react-router-dom';

const today = new Date();

const Footer = () => {
  return (
    <div className={styles.footer__container}>
      <div className={styles.footer__top}>
      <div className={styles.footer__left}>
        <div className={styles.signUpText}>
          <h3>
            Get Connected
          </h3>
          <p>
            Sign-Up & stay ahead of the Game with Industry 
          </p>
        </div>
        <div className={styles.header__content__input}>
          <input type="email" placeholder='Your Email Address' />
          <button type='button'>Subscribe</button>
          </div>
        </div>
      <div className={styles.footer__right}>
        <div className={styles.nav1}>
          <ul>
            <Link to='/'>
              <li>Home</li>
            </Link>
            <a href='/#av'>
            <li>AV Programming</li>
            </a>
            <a href='/#design'>
            <li>Design UX/UI</li>
            </a>
            <Link to='/ourWork'>
            <li>Our Work</li>
            </Link>
          </ul>
          </div>
          <div className={styles.nav2}>
          <ul>
          <Link to='/blog'>
            <li>Blog</li>
            </Link>
            <Link to='/ourTeam'>
            <li>Our Team</li>
            </Link>
            <Link to='/contact'>
            <li>Contact Us</li>
            </Link>
            <Link to='/career'>
            <li>Careers</li>
            </Link>
          </ul>
      </div>
      </div>
      </div>
      <div className={styles.footer__centered}>
        <div className={styles.certifications}>
          <div className={styles.footer__logo}>
            <div className={styles.cert__logo}>
              <a 
              href='https://support.crestron.com/app/answers/detail/a_id/2315/~/what-is-a-crestron-service-provider-%28csp%29%3F'
              target="_blank" rel="noopener noreferrer"
              >
                <img src={CSP} alt='Crestron Service Provider Certified' />
              </a>
            </div>
          </div>
        </div>
          <div className={styles.copywrite}>
            <h5>
              Designed by Ty Gyorfy
              <br />
              @Code-Distillers.
            </h5>
              <p>
                © {today.getFullYear()}. All Rights Reserved.
              </p>
          </div>
      </div>

    </div>
  )
}

export default Footer;