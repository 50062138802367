import React from 'react';
import { Feature } from '../../components';
import Cube from '../../components/cube/Cube';
import styles from './designPage.module.css';

const designDataMain = [
  {
    title:'Control Panels',
    text:'Crestron Touch Panels, iPad UI/UX and Custom ReactJS/HTML templates. Creatively designed User Interfaces that are easy to use. Fully responsive across all devices to keep you covered',
  },
  {
    title:'Web Applications',
    text:'Get a Custom Website or Application built the way you want it starting from scratch or save by starting with a variety of Mobile 1st responsive websites templates. Request a quote now.'
  },
  {
    title:'Technologies',
    text:'We Change With The Times! In this industry you have to stay up to date to stay alive. We study and use many Modern Technologies, Frameworks and Libraries to build projects across dozens of different industries.'
  },
  {
    title:'Control Panels',
    text:'Crestron Touch Panels, iPad UI/UX and Custom ReactJS/HTML templates. Creatively designed User Interfaces that are easy to use. Fully responsive across all devices to keep you covered',
  },
  {
    title:'Web Applications',
    text:'Get a Custom Website or Application built the way you want it starting from scratch or save by starting with a variety of Mobile 1st responsive websites templates. Request a quote now.'
  },
  {
    title:'Technologies',
    text:'We Change With The Times! In this industry you have to stay up to date to stay alive. We study and use many Modern Technologies, Frameworks and Libraries to build projects across dozens of different industries.'
  },
]

const DesignPage = () => {
  return (
    <div className={`${styles.design} ${styles.section__padding}`} >
      <div className={styles.impressions}>
        <h1 className={styles.gradient__text}>
          1st Impressions Don't Get 2nd Chances.
        </h1>
      </div>
      <div className={styles.design__heading}>
          <br />
            <h1 className={styles.gradient__text}>
              We Mix Innovative Logic w/ Modern Technologies & Custom Designs. 
            </h1>
              <p>
                Make Your Mark.
              </p>
                <Cube />
      </div>
      <div className={styles.design__features}>
      {designDataMain.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
      </div>   
    </div>
  )
}

export default DesignPage;