import React from 'react';
import styles from "./scheduler.module.css";

//Links to google calendar for clients direct booking "INTRO CALL"
const Scheduler = () => {
    return (
        <div className={styles.scheduler}>
            <div className={styles.calendar}>
                <div className={styles.calendar_msg}>
                    <h3>
                        Ready to schedule a call with us?
                    </h3>
                    <p>
                        View our schedule here and book a call with Code-Distillers.
                    </p>
                </div>
                <div className={styles.calendar_btn}>
                    <a target="_blank" rel="noreferrer" href="https://calendar.app.google/ECpnBkN6fYdD9fdu7">Schedule Now</a>
                </div>
            </div>
        </div>
    )
}

export default Scheduler;