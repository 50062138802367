import React from 'react';
import { Feature } from '../../components';
import styles from './avPage.module.css';
import { danteAudio, bss, planar, qsys, crestron, avPro, simpl} from './avimports';

const AVPage = () => {
  return (
    <div className={`${styles.av} ${styles.section__padding}`} >
        <div className={styles.av__feature}>
          <h1 className={styles.gradient__text}> 
            Over 10 Years Of Commercial AV Programming Experience.
          </h1>
            <p>
              Certified Crestron Service Provider that works with other popular brands of equipment & software.
            </p>
        </div>
            <div className={styles.avBrands}>
                <div className={styles.avBrand1}>
                    <img src={avPro} alt='AV Pro Video Wall' /> 
                    <img src={qsys} alt='Q-sys' /> 
                    <img src={crestron} alt='Crestron' /> 
                </div>
                <div className={styles.avBrand2}>
                    <img src={planar} alt='Planar' /> 
                    <img src={danteAudio} alt='Dante Audio' /> 
                    <img src={bss} alt='BSS' /> 
                    <img src={simpl} alt='Simpl Pro' />
                </div>
            <h6>
              * Listed are some of the many brands we service. 
              <br />
              ** NOTE: We are NOT affiliated with NOR endorsed by the companies listed above.   
            </h6>
            </div>
          <div className={styles.av__heading}>
            <h3 className={styles.gradient__text}>
              Programmable solutions for Audio, Video, Lighting and more...
            </h3>
            <p>LOGIC THAT WORKS</p>
          </div>
                <div className={styles.av__container}>
                  <Feature title="Audio" text="Crisp clean audio that is easy to control for areas like Stadiums, Auditoriums, Conference Rooms and more. Let us plan and program the ideal audio system for your space. 'Cue the music!'.. Schedule a Free Consultation Today."/>
                  <Feature title="Video" text="We make it fast & easy to route all your video sources to the right display. Giving you the controls all in one place with responsive & easy to use Touch Panels or iPad. It's important to know what you'll need out of your system and we can help."/>
                  <Feature title="Lighting" text="Lighting Systems like Lutron and others can be programmed to 'set the mood' or work when as needed. Save on utilities with automated timers and sensors. Area Selection makes it easy to adjust lighting manually or use custom presets."/>
                  <Feature title="Automation" text="Have a Smart Project idea? Let our experienced team assist in planning and implementing a customized system designed to fit your needs. Contact us Today and get more information on how we can manage your next Project."/>
                  <Feature title="Audio" text="Crisp clean audio that is easy to control for areas like Stadiums, Auditoriums, Conference Rooms and more. Let us plan and program the ideal audio system for your space. 'Cue the music!'.. Schedule a Free Consultation Today."/>
                  <Feature title="Video" text="We make it fast & easy to route all your video sources to the right display. Giving you the controls all in one place with responsive & easy to use Touch Panels or iPad. It's important to know what you'll need out of your system and we can help."/>
                  <Feature title="Lighting" text="Lighting Systems like Lutron and others can be programmed to 'set the mood' or work when as needed. Save on utilities with automated timers and sensors. Area Selection makes it easy to adjust lighting manually or use custom presets."/>
                </div>
    </div>
  )
}

export default AVPage;