import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGitAlt,
    faHtml5,
    faReact,
    faFigma,
    faNodeJs,
    faCss3Alt
} from '@fortawesome/free-brands-svg-icons'
import styles from './cube.module.css'

const Cube = () => {

return (
    <div className={styles.cube__container}>
    <div className={styles.cubespinner}>
    <div className={styles.face1}>
        <FontAwesomeIcon icon={faHtml5} color="#DD0031" />
    </div>
    <div className={styles.face2}>
        <FontAwesomeIcon icon={faReact} color="#5ed4f4" />
    </div>
    <div className={styles.face3}>
        <FontAwesomeIcon icon={faCss3Alt} color="#28A4D9" />
    </div>
    <div className={styles.face4}>
        <FontAwesomeIcon icon={faFigma} color="#f06529" />
    </div>
    <div className={styles.face5}>
        <FontAwesomeIcon icon={faNodeJs} color="#EFD81D" />
    </div>
    <div className={styles.face6}>
        <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
    </div>
    </div>
</div>
)
}

export default Cube;