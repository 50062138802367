import React from 'react';
import styles from './possibility.module.css';
import subLogo from '../../assets/Logo-Code-Distillers/sub-Logo.png'
import { Link } from 'react-router-dom';

const Possibility = () => {
  return (
    <div className={styles.explore}>
      <div className={styles.explore__title}>
        <h1 className={styles.exploreh1}>
        Explore Endless Possibilities with..
        </h1>
        <img src={subLogo} alt='Code-Distillers Logo'/>
      </div>
        <div className={styles.link__boxes}>
          <Link to='/contact'>
          <div className={styles.contact__box}>
            <div className={styles.card}>
              <h3 className={styles.title1}>
                Request a Quote
                <br />
                or 
                <br />
                Schedule a Free Consultations
              </h3>
            </div>
          </div>
          </Link>
          <Link to='/ourTeam'>
          <div className={styles.team__box}>
            <div className={styles.card}>
              <h3 className={styles.title2}>
                Meet the Team
              </h3>
            </div>
          </div>
          </Link>
          <Link to='/ourWork'>
          <div className={styles.work__box}>
            <div className={styles.card}>
              <h3 className={styles.title3}>
                View Past Projects
              </h3>
            </div>
          </div>
          </Link>
          <Link to='/blog'>
          <div className={styles.blog__box}>
            <div className={styles.card}>
              <h3 className={styles.title4}>
                Read a Blog Post
              </h3>
            </div>
          </div>
          </Link>
        </div>
    </div>
  )
}

export default Possibility;